<template>
	<aside class="sidebar" :class="opened ? 'opened' : ''">
		<router-link v-if="isDesktop" id="button-logo" class="sidebar__top" :to="{ name: 'Dashboard' }">
			<img src="@/assets/images/icon-light.svg" />
		</router-link>
		<span v-else class="sidebar__head">
			<Logo />
			<svg @click="close()" class="icon-button stroke" viewBox="0 0 24 24">
				<path d="M4.222 4.222l15.556 15.556M4.222 19.778L19.778 4.222"></path>
			</svg>
		</span>
		<nav class="navigation navigation--primary">
			<b-tooltip v-for="(r, i) in primary" :label="title(r)" :key="i" class="is-capitalized" type="is-white" position="is-right" v-show="verifyShowMenu(r.name)">
				<router-link :id="`button-${r.name.toLowerCase()}`" class="navigation__link" @click.native="close()" :to="r">
					<span v-if="!isDesktop" class="is-capitalized">{{ title(r) }}</span>
					<svg-icon v-else :icon="r.icon"></svg-icon>
				</router-link>
			</b-tooltip>
		</nav>
		<nav class="navigation navigation--secondary">
			<b-tooltip :label="$t('menu.profile')" type="is-white" position="is-right">
				<router-link id="button-profile" class="navigation__link" @click.native="close()" :to="{ name: 'Profile' }">
					<span v-if="!isDesktop">{{ $t('menu.profile') }}</span>
					<svg-icon v-else icon="profile"></svg-icon>
				</router-link>
			</b-tooltip>
			<b-tooltip :label="$t('menu.logout')" type="is-white" position="is-right">
				<a @click="logout" id="button-logout" class="navigation__link navigation__link--logout">
					<span v-if="!isDesktop">{{ $t('menu.logout') }}</span>
					<svg-icon v-else icon="logout"></svg-icon>
				</a>
			</b-tooltip>
		</nav>
	</aside>
</template>

<script>
import Menu from '@/router/menu'
import Logo from '@/components/Logo'
import Icon from '@/components/Icon'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { responsive } from '@/mixins/responsive'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'Sidebar',
	mixins: [responsive],
	components: {
		Logo,
		'svg-icon': Icon
	},
	data() {
		return {
			expandOnHover: false,
			opened: false,
			overlay: false,
			id: '',
			enableDashboard: process.env.VUE_APP_ENABLE_DASHBOARD === 'true' ? true : false
		}
	},
	methods: {
		...mapActions('user', [
			'deleteUserData'
		]),
		close() {
			this.opened = false
			eventHub.$emit('close-menu')
		},
		title(r) {
			// return r.meta ? r.meta.title : r.name
			return this.$tc(r.meta.title, 2)

		},
		async logout(event) {
			try {
				event.preventDefault()
				await Api.post('users/logout')
			} catch (e) {
				console.log(e)
			}

			this.deleteUserData()
			this.$router.push('/')
		},
		verifyShowMenu(name) {
			if (name.toLowerCase() == 'dashboard' && !this.enableDashboard) {
				return false
			}

			if (this.permissions) {
				const menu = this.permissions.find(e => e.slug === name.toLowerCase())

				if (menu) {
					return menu.role.read
				}
			}

			return false
		}
	},
	mounted() {
		eventHub.$on('open-menu', () => {
			this.opened = true
		})
	},
	computed: {
		primary() {
			return Menu
		},
		...mapState('user', [
			'user',
			'permissions'
		])
	}
}
</script>
